.marquee-animated {
  margin-bottom: 0px;
  width: 100%;
  display: inline-block;
  animation: move-ltr 12000ms linear infinite;
}

@keyframes move-ltr {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}
